import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Player} from '../../model/user';
import {TrainingAttendance} from '../../model/season';

@Component({
  selector: 'app-players-list-checkbox',
  templateUrl: './players-list-checkbox.component.html',
  styleUrl: './players-list-checkbox.component.scss'
})
export class PlayersListCheckboxComponent implements OnInit {

  @Input() players!: Player[];
  @Input() checkedItems!: TrainingAttendance[];
  selectedOptions: Map<number, boolean> = new Map();
  @Output() selectedChange = new EventEmitter<Map<number, boolean>>();

  ngOnInit(): void {
    this.players.forEach(p => {
      this.selectedOptions.set(p.id, false);
      if (this.checkedItems && this.checkedItems.length > 0) {
        this.checkedItems.forEach(c => {
          if (c.playerId === p.id && c.isPresent) {
            this.selectedOptions.set(c.playerId, true);
          }
        });
      }
    });
  }

  onCheckboxChange(optionId: number, isChecked: boolean): void {
    if (isChecked) {
      this.selectedOptions.set(optionId, true);
    } else {
      this.selectedOptions.set(optionId, false);
    }
    this.selectedChange.emit(this.selectedOptions);
  }
}
